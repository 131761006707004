














































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsProfilingPoliticallyExposedPerson
  from '@/vue-app/components/contract-savings/profiling/ContractSavingsProfilingPoliticallyExposedPerson.vue';
import ActualSupplierOfAssets
  from '@/vue-app/components/contract-savings/additional-information/ActualSupplierOfAssets.vue';
import ActualOwnerOfResources
  from '@/vue-app/components/contract-savings/additional-information/ActualOwnerOfResources.vue';
import ContractSavingsProfilingWorkKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/profiling/work/contract-savings-profiling-work-kuspit-view-model';

@Component({
  name: 'ContractSavingsProfilingWorkKuspit',
  components: {
    ActualOwnerOfResources,
    ContractSavingsProfilingPoliticallyExposedPerson,
    ActualSupplierOfAssets,
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsProfilingWorkKuspit extends Vue {
  profiling_work_kuspit_view_model = Vue.observable(
    new ContractSavingsProfilingWorkKuspitViewModel(),
  );

  @Watch('profiling_work_kuspit_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('profiling_work_kuspit_view_model.inputs.have_business_activities')
  onBusinessActivityChange(business_activity: any) {
    this.profiling_work_kuspit_view_model.setIfBusinessActivity(business_activity);
  }

  @Watch('profiling_work_kuspit_view_model.inputs.job_type')
  onJobTypeChange() {
    this.profiling_work_kuspit_view_model.searchActivities();
  }

  @Watch('profiling_work_kuspit_view_model.acceptance.not_pep')
  handleConfirmNoPoliticallyExposedChange(new_confirmation: boolean) {
    this.profiling_work_kuspit_view_model.handleAgreementChange(
      'not_politically_exposed_person_kuspit',
      new_confirmation,
    );
  }

  @Watch('profiling_work_kuspit_view_model.acceptance.real_provider')
  handleConfirmRealProviderChange(new_confirmation: boolean) {
    this.profiling_work_kuspit_view_model.handleAgreementChange(
      'resource_provider_kuspit',
      new_confirmation,
    );
  }

  @Watch('profiling_work_kuspit_view_model.acceptance.resource_owner')
  handleConfirmResourceOwnerChange(new_confirmation: boolean) {
    this.profiling_work_kuspit_view_model.handleAgreementChange(
      'resource_owner_kuspit',
      new_confirmation,
    );
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const save_step = await this.profiling_work_kuspit_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.profiling_work_kuspit_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.profiling_work_kuspit_view_model.initialize();

    this.$emit('loadingInfo', this.profiling_work_kuspit_view_model.is_loading);
  }
}

